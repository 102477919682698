.gray {
  color: #929499;
}

.red {
  color: #d13c34;
}

.green {
  color: #57d187;
}

.yellow {
  color: #fdbb97;
}

.blue {
  color: #53c2ef;
}

.orange {
  color: #f7981c;
}
