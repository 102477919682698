.list {
  font-size: 13px;
}

.list li > span {
  display: block;
  color: #929499;
  font-size: 12px;
}

.list li {
  display: inline-block;
  margin: 0 20px 10px 0;
}
