.iconWrapper {
  display: flex;
  align-items: center;
}
.toggle {
  font-size: 13px;
  color: #2ea2f8;
  margin-left: 10px;
  cursor: pointer;
}
