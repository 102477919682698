.button {
  margin-top: 32px;
}

.search {
  margin: 26px 0 50px;
}

.link {
  font-size: 20px;
}
