.back {
  display: inline-block;
  vertical-align: middle;
  line-height: 24px;
}

.back__icon {
  display: inline-block;
  color: #5691f0;
  cursor: pointer;
  line-height: 0;
  vertical-align: middle;
}

.back__content {
  display: inline-block;
  padding-left: 16px;
  vertical-align: middle;
  color: #18253a;
  text-decoration: none;
  font-size: 16px;
  line-height: 26px;
}

.back_icon_right .back__icon {
  float: right;
  transform: rotate(180deg);
  line-height: 1;
}

.back_icon_right .back__content {
  padding: 0 16px 0 0;
  font-size: 15px;
}

.back_detached {
  display: block;
  margin-bottom: 30px;
}
