.date {
  flex-basis: 100%;
  display: flex;
  flex-flow: row nowrap;
  margin-top: 10px;
  padding-right: 20px;
  align-items: flex-end;
}

@media screen and (min-width: 768px) {
  .date {
    flex-basis: 50%;
  }
}

.input {
  z-index: 1;
  flex: 1 1 auto;
}

.inputs {
  position: relative;
  padding-left: 20px;
  z-index: 1;
  flex: 1 1 auto;
}

.inputs:first-child {
  padding-left: 0;
}

.inputs:first-child:before {
  display: none;
}

.inputs:before {
  position: absolute;
  bottom: 0;
  left: 7px;
  line-height: 37px;
  content: "-";
}
