.control {
  display: inline-block;
  width: 21px;
  height: 14px;
}

.control span,
.control::after,
.control::before {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #fa6431;
  will-change: transform;
  transition: transform 0.2s ease;
}

.control span {
  margin: 4px 0;
}

.control.active::after {
  transform: rotate(-45deg) translate(2px, -6px);
}

.control.active::before {
  transform: rotate(45deg) translate(3px, 6px);
}

.control.active span {
  opacity: 0;
}
