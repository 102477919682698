.main__nav {
  display: inline;
}

.button {
  font-size: 14px;
  font-weight: 700;
  color: #22a4ff;
}

.button_default {
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  text-align: left;
}

.button svg {
  margin-left: 10px;
}

.button_active svg {
  transform: rotate(180deg);
}

.more {
  display: none;
  margin-top: 20px;
}

.more_show {
  display: inline-block;
}

.more_show_block {
  display: block;
}

.more_nav {
  margin-top: 10px;
}
