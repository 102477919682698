.row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.row > div {
  flex: 1 1 auto;
}

.row .right {
  text-align: right;
}
