.buttons {
  width: 630px;
  margin-top: 40px;
}

.buttons__row {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 30px;
  justify-content: space-between;
}

.buttons__column {
  flex: 0 1 48%;
}

.row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 10px;
}

.row > div {
  flex: 1 1 50%;
}

.row .right {
  text-align: right;
}
