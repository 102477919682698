.main {
  margin-top: 10px;
  display: flex;
  flex-flow: row nowrap;
}

.main > div {
  flex: 1 1 auto;
}

.main > div:last-child {
  flex: 0 0 auto;
}

.main .button {
  background-color: #f5f5f5;
  padding: 10px;
  border: 1px solid #dedede;
  margin-left: -1px;
}
