.form {
  max-width: 500px;
}

.form > div {
  margin-bottom: 20px;
}

.title {
  font-size: 14px;
  font-weight: bold;
}
