.aside {
  position: relative;
  flex: 0 0 280px;
  font-size: 12px;
  color: #fff;
  background-image: linear-gradient(-45deg, #007796, #003076);
  overflow: hidden;
}

.aside .logo {
  display: block;
  margin-left: 10px;
  padding: 40px 40px 0;
}

.aside .logo img {
  height: 32px;
}

.aside .add {
  display: block;
  width: 100%;
  padding: 20px 0 20px 37px;
  text-align: left;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
}

.aside .add i {
  color: #1fb6f5;
  font-size: 10px;
  margin-right: 16px;
}

.aside .menu-control {
  display: none;
  flex: 0 1 100px;
  text-align: right;
  order: 3;
}

@media (max-width: 860px) {
  .aside {
    flex: 0 0 84px;
    padding: 0 30px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
  .aside .line {
    display: none;
  }
  .aside .add {
    width: auto;
    padding: 0;
    order: 1;
    flex: 0 0 120px;
  }
  .aside .add i {
    color: #fa6431;
  }
  .aside .logo {
    width: 150px;
    margin: 0;
    order: 2;
    background-position: center;
  }
  .aside .menu-control {
    display: inline-block;
  }
}
