.select {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  user-select: none;
  font-size: 12px;
}

.select .placeholder {
  color: #999;
}

.select.disabled .control {
  opacity: 0.5;
  background-color: #f5f5f5;
  pointer-events: none;
}

.select .control {
  position: relative;
  width: 100%;
  min-width: 120px;
  min-height: 37px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px 30px 10px 10px;
  line-height: 15px;
  font-size: 14px;
  border: 1px solid #dedede;
  cursor: pointer;
}

.select .control.error {
  border-color: #ff1f44;
}

.select .control.error .required-label {
  border-bottom-color: #ff1f44;
}

.select.multiple .control {
  padding: 5px 30px 5px 10px;
  line-height: 24px;
}

.select .label {
  margin-bottom: 11px;
}

.select .list {
  position: absolute;
  left: -9999px;
  top: -9999px;
  margin-top: -1px;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #dedede;
  background-color: #fff;
  z-index: -1;
}

.select .list li {
  position: relative;
  padding: 10px 50px 10px 10px;
  line-height: 16px;
  word-break: break-all;
  cursor: pointer;
}

.select .list li:hover {
  background-color: #f5f5f5;
}

.select .list li.active {
  color: #11d8fb;
}

.select .list li.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.select .list li.disabled:hover {
  background: none;
}

.select .list li .icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: #44e240;
}

.select .list li:first-child::before,
.select .list li:first-child::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: -5px;
  right: 10px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  z-index: 2;
}

.select .list li:first-child::after {
  top: -6px;
  border-bottom-color: #dedede;
  z-index: 1;
}

.select .list li:first-child:hover::before {
  border-bottom-color: #f5f5f5;
}

.select.top .list {
  bottom: 37px;
}

.select.top .list li:first-child::before,
.select.top .list li:first-child::after {
  display: none;
}

.select.top .list li:last-child::before,
.select.top .list li:last-child::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -5px;
  right: 10px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
  z-index: 2;
}

.select.top .list li:last-child::after {
  bottom: -6px;
  border-top-color: #dedede;
  z-index: 1;
}

.select.top .list li:last-child:hover::before {
  border-top-color: #f5f5f5;
}

.select.open .list {
  top: auto;
  left: 0;
  right: 0;
  z-index: 10000;
}

.select.open .arrow {
  transform: translateY(-50%) rotate(180deg);
}

.arrow {
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #282828;
  z-index: 2;
  transition: transform 0.2s ease;
  will-change: transform;
}

.multiple-list li {
  display: inline-block;
  padding: 0 10px;
  background-color: #f5f7f9;
  font-size: 10px;
  line-height: 24px;
  margin-right: 10px;
  vertical-align: top;
}

.error-label {
  position: absolute;
  top: 100%;
  right: -1px;
  padding: 8px 15px 7px 13px;
  color: #fff;
  background-color: #ff1f44;
  font-size: 10px;
  line-height: 1.4;
  z-index: 1000;
}
