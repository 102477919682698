.buttonGroup {
  display: flex;
  margin-bottom: 20px;
}

.button {
  margin: 0 20px;
}

.button:first-child {
  margin-left: 0;
}

.iframe {
  margin-top: 20px;
  width: 100%;
  height: 800px;
  padding: 30px 50px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}
