.global {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

.global > div {
  flex: 5 0 0;
  padding: 20px;
  color: #fff;
  font-size: 14px;
}

.global > div .count {
  font-size: 24px;
  margin-bottom: 10px;
}

.global > div:nth-of-type(1) {
  background-color: #45d0e8;
}

.global > div:nth-of-type(2) {
  background-color: #f8cb00;
}

.global > div:nth-of-type(3) {
  background-color: #65ce56;
}

.global > div:nth-of-type(4) {
  background-color: #f8752e;
}

.global > div:nth-of-type(5) {
  background-color: #34baa0;
}
