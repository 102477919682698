.search {
  flex-basis: 100%;
  display: flex;
  flex-flow: row nowrap;
  margin-top: 10px;
  padding-right: 20px;
  align-items: flex-end;
}

@media screen and (min-width: 768px) {
  .search {
    flex-basis: 50%;
  }
}

.search__input {
  flex: 1 1 auto;
}

.search__select {
  flex: 0 0 170px;
  margin-left: -1px;
}
