.table {
  font-size: 12px;
  line-height: 20px;
  color: #3d3d3d;
  table-layout: fixed;
  border-collapse: collapse;
  border: #e0e0e0 1px solid;
}

.table thead {
  background-color: #f5f7f9;
  user-select: none;
  font-size: 10px;
}

.table thead th {
  padding: 14px 20px;
  text-transform: uppercase;
  font-weight: 700;
  color: #929499;
  text-shadow:
    1px 1px 2px #fff,
    0 0 0 #929499;
  cursor: default;
}

.table tbody {
  background-color: #fff;
}

.table td,
.table th {
  padding: 16px 20px;
  text-align: left;
  vertical-align: middle;
  border-top: #e0e0e0 1px solid;
  word-break: break-all;
}

.table td.left,
.table th.left {
  text-align: left;
}

.table td.center,
.table th.center {
  text-align: center;
}

.table td.right,
.table th.right {
  text-align: right;
}

.table td:first-child,
.table th:first-child {
  padding-left: 35px;
}

.table td:last-child,
.table th:last-child {
  padding-right: 35px;
}

.table.zebra tbody tr:nth-of-type(2n),
.table.zebra tbody + tbody:nth-of-type(2n - 1) {
  background-color: #fcfcfc;
}

.table.hovered tbody tr:hover {
  background-color: #f3fdff;
}

.table .placeholder td {
  padding: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #ababab;
  font-size: 10px;
}

.table .placeholder:hover {
  background-color: transparent !important;
}

.table td table {
  box-shadow: none;
}

.table td table tbody,
.table td table tr {
  background-color: inherit !important;
}

.table td table td {
  box-shadow: none;
}

.table td table td:first-child {
  padding-left: 0;
}

@media screen and (max-width: 767px) {
  .table.responsive {
    display: block;
    box-shadow: none;
    border-top: none;
  }
  .table.responsive thead,
  .table.responsive tfoot {
    display: none;
  }
  .table.responsive tbody,
  .table.responsive tr,
  .table.responsive td,
  .table.responsive th {
    display: block;
  }
  .table.responsive tbody[hidden],
  .table.responsive tr[hidden],
  .table.responsive td[hidden],
  .table.responsive th[hidden] {
    display: none;
  }
  .table.responsive td:first-child,
  .table.responsive th:first-child {
    padding-left: 20px;
  }
  .table.responsive td::before,
  .table.responsive th::before {
    content: attr(data-title) ": ";
    text-transform: uppercase;
    font-size: 0.95em;
    font-weight: 600;
    line-height: 1;
    display: block;
    margin-bottom: 4px;
  }
  .table.responsive td {
    width: 100% !important;
  }
  .table.responsive tr + tr,
  .table.responsive tbody + tbody {
    margin-top: 10px;
  }
}
